import React, { useState, useRef } from "react";
import styles from "../../css/facility/AppAccessController..module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { SERVICES } from "../../helpers/utils_apps";
import { setFacilityAppAccess } from "../../helpers/utils_facility";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
// components
import FacilityAccessSwitch from "./FacilityAccessSwitch";

import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	save: {
		padding: ".5rem 1rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
	cancel: {
		padding: ".5rem 1rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
};

const wasTouched = (keyName, touched) => {
	const wasTouched = touched[keyName] ?? false;
	return wasTouched;
};

const enableBtn = (formState, currentFacility) => {
	if (isEmptyVal(currentFacility?.facilityID)) return false;

	const { touched } = formState;
	const portalTouched = wasTouched("AdminPortal", touched);
	const trackerTouched = wasTouched("AdvantageTracker", touched);
	const legacyTouched = wasTouched("SeniorCareVB", touched);

	const hasChanges = trackerTouched || legacyTouched || portalTouched;

	return hasChanges;
};

const AppAccessController = ({
	app = {},
	initialVal,
	currentUser,
	currentFacility,
	handleAlert,
}) => {
	const initialState = useRef(initialVal);
	const { formState, setFormState, handleReset } = useForm({
		[app.appName]: initialVal,
	});
	const { values, touched } = formState;

	const handleEnableAccess = (e) => {
		const { name, checked } = e.target;
		setFormState({
			...formState,
			values: {
				...values,
				[name]: checked,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	// fires off single app access update
	const saveAccessChanges = async (appName) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const appID = SERVICES[appName].appID;
		const isAccessible = values[appName];

		
		
		
		
		

		// fire off request
		// const wasUpdated = true;

		const wasUpdated = await setFacilityAppAccess(token, {
			appID,
			facilityID,
			isAccessible,
		});
		if (wasUpdated) {
			return handleAlert(appName, wasUpdated);
		} else {
			return handleAlert(appName, wasUpdated);
		}
	};

	const cancelChanges = (e) => {
		handleReset(e);
	};

	return (
		<div className={styles.AppAccessController}>
			<div className={styles.AppAccessController_switch}>
				<FacilityAccessSwitch
					key={`ACCESS--${app.appName}`}
					appName={app.appName}
					label={app.label}
					isLocked={false}
					isEnabled={values?.[app.appName]}
					handleAccess={handleEnableAccess}
				/>
			</div>
			<div className={styles.AppAccessController_actions}>
				<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={!enableBtn(formState, currentFacility)}
					handleClick={() => saveAccessChanges(app.appName)}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default AppAccessController;

AppAccessController.defaultProps = {};

AppAccessController.propTypes = {};

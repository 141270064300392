import React, { useState, useEffect } from "react";
import styles from "../../css/reports/AllActiveUsersReport.module.scss";
import { PropTypes } from "prop-types";
import ButtonSM from "../shared/ButtonSM";
import {
	getActiveCommunitiesList,
	getActiveUsersList,
} from "../../helpers/utils_reports";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import UserReportEntry from "./UserReportEntry";
import { blue, green, pink, red } from "../../helpers/utils_styles";
import SpinnerSM from "../shared/SpinnerSM";
import { groupBy } from "../../helpers/utils_processing";
import {
	convertArrayDataToCSV,
	saveDataToCSV,
	saveFile,
} from "../../helpers/utils_files";
import { format } from "date-fns";
import { getResidentsByFacility } from "../../helpers/utils_resident";

// REQUIREMENTS:
// - First Name
// - Last Name
// - Email
// - Company/Community (if with multiple communities just list the parent's name)

const customCSS = {
	btn: {
		padding: ".8rem 1.4rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginBottom: "4rem",
	},
	searching: {
		color: red[600],
	},
	copyAll: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: blue[600],
	},
	copyEmail: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: green[600],
		marginRight: "1rem",
	},
	csv: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: pink[600],
		marginRight: "1rem",
	},
};

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((entry) => {
		const first = entry?.FirstName;
		const last = entry?.LastName;
		const facilityName = entry?.CommunityName;
		const userID = entry?.UserLoginID;
		const email = entry?.LoginNameByEmail ?? entry?.LoginName;

		if (
			first?.toLowerCase().includes(val) ||
			first?.toLowerCase().startsWith(val) ||
			last?.toLowerCase().includes(val) ||
			last?.toLowerCase().startsWith(val) ||
			facilityName?.toLowerCase().includes(val) ||
			facilityName?.toLowerCase().startsWith(val) ||
			email?.toLowerCase().includes(val) ||
			email?.toLowerCase().startsWith(val) ||
			userID?.toLowerCase().includes(val) ||
			userID?.toLowerCase().startsWith(val)
		) {
			return entry;
		} else {
			return null;
		}
	});
};

const extractDataIntoLimitedArray = (data = [], residentCounts = {}) => {
	const newList = data.map((entry) => {
		const id = entry?.CommunityName;
		const facility = residentCounts?.[id][0];

		const newEntry = {
			CommunityName: entry?.CommunityName,
			Email: entry?.LoginNameByEmail ?? entry?.LoginName,
			UserName: `${entry?.FirstName} ${entry?.LastName}`,
			LastLogin: !isEmptyVal(entry?.LastLoginDate)
				? format(entry?.LastLoginDate, "MM/DD/YYYY h:mm A")
				: "UNKNOWN",
			ResidentCount: facility?.ResidentCount ?? "UNKNOWN",
		};
		return newEntry;
	});

	return newList;
};

const advancedCopyAll = (searchVal, reportData, clonedReportData) => {
	if (!isEmptyVal(searchVal)) {
		// copy only search results
		const text = clonedReportData.reduce((data, item) => {
			const name = `${item?.LastName}, ${item?.FirstName}`;
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}`;
			const facility = `${item?.CommunityName}`;

			const userRow = `${name}: ${email} (${facility})\n\n`;
			data += `${userRow}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	} else {
		// copy all
		const text = reportData.reduce((data, item) => {
			const name = `${item?.LastName}, ${item?.FirstName}`;
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}`;
			const facility = `${item?.CommunityName}`;

			const userRow = `${name}: ${email} (${facility})\n\n`;
			data += `${userRow}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	}
};
const advancedCopyEmailsOnly = (searchVal, reportData, clonedReportData) => {
	if (!isEmptyVal(searchVal)) {
		// copy only search results
		const text = clonedReportData.reduce((data, item) => {
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}\n\n`;
			data += `${email}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	} else {
		// copy all
		const text = reportData.reduce((data, item) => {
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}\n\n`;
			data += `${email}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	}
};

const getUserFacilityData = (userEntry, reportMetrics) => {
	const { residentCounts } = reportMetrics;
	const id = userEntry?.CommunityName;
	const facility = residentCounts?.[id][0];

	return facility;
};

const AllActiveUsersReport = ({
	globalState = {},
	currentFacility = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { parentsMap } = globalState;
	const { raw: facilities, ids, names } = parentsMap;
	// local state
	const [isLoading, setIsLoading] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [clonedReportData, setClonedReportData] = useState([]);
	const [reportMetrics, setReportMetrics] = useState({
		byCommunity: {},
		residentCounts: {},
	});
	const [searchVal, setSearchVal] = useState("");

	const searchUsers = (e) => {
		const { value } = e.target;

		if (isEmptyVal(value)) {
			setSearchVal("");
			return setClonedReportData(reportData);
		} else {
			const results = advancedSearch(value, reportData);
			
			setSearchVal(value);
			return setClonedReportData(results);
		}
	};

	// formats list entries as follows: "<last/>, <first/>: <email/> (<community/>)"
	const copyAll = (e) => {
		advancedCopyAll(searchVal, reportData, clonedReportData);
	};
	const copyEmailsOnly = (e) => {
		// iterate thru each record, extract email address format w/ line-break & append to string
		advancedCopyEmailsOnly(searchVal, reportData, clonedReportData);
	};
	const createAndDownloadCSV = (e) => {
		
		const { residentCounts } = reportMetrics;
		// extract limited user data into new array
		const newData = extractDataIntoLimitedArray(reportData, residentCounts);
		const csvData = convertArrayDataToCSV(newData);
		const now = format(new Date(), "MM_DD_YYYY");
		const fileName = `ALAActiveUsers-${now}.csv`;

		
		
		

		// auto-download .csv file
		saveDataToCSV(csvData, fileName);
	};

	const fetchUsersList = async () => {
		const { token } = currentUser;
		setIsLoading(true);
		// const usersList = await getActiveUsersList(token);
		const [usersList, residentData] = await Promise.all([
			getActiveUsersList(token),
			getActiveCommunitiesList(token),
		]);

		if (!isEmptyArray(usersList)) {
			const byCommunity = groupBy([...usersList], (x) => x?.CommunityName);
			const residentCountByCommunity = groupBy(
				[...residentData],
				(x) => x?.CommunityName
			);

			setIsLoading(false);
			setReportMetrics({
				byCommunity: byCommunity,
				residentCounts: residentCountByCommunity,
			});
			setClonedReportData(usersList);
			return setReportData(usersList);
		} else {
			setIsLoading(false);
			setReportMetrics({});
			setClonedReportData([]);
			return setReportData([]);
		}
	};

	return (
		<div className={styles.AllActiveUsersReport}>
			<div className={styles.AllActiveUsersReport_details}>
				<div className={styles.AllActiveUsersReport_details_desc}>
					NOTE: clicking "Run Report" will execute the report with data current
					to the moment the button is clicked.
				</div>
			</div>
			<div className={styles.AllActiveUsersReport_actions}>
				<ButtonSM handleClick={fetchUsersList} customStyles={customCSS.btn}>
					{isLoading ? <SpinnerSM color="#ffffff" /> : "Run Report"}
				</ButtonSM>
			</div>
			<div className={styles.AllActiveUsersReport_report}>
				<div className={styles.AllActiveUsersReport_report_summary}>
					{/* TOTAL ACTIVE USERS COUNT */}
					{!isEmptyArray(reportData) && (
						<div className={styles.AllActiveUsersReport_report_summary_count}>
							Total: <b>{reportData?.length ?? 0} active users</b>
						</div>
					)}
					{/* SEARCH RESULTS COUNT */}
					{!isEmptyArray(reportData) && !isEmptyVal(searchVal) && (
						<div className={styles.AllActiveUsersReport_report_summary_count}>
							Search Results:{" "}
							<b style={customCSS.searching}>
								{clonedReportData?.length ?? 0} matching results
							</b>
						</div>
					)}
				</div>
				<div className={styles.AllActiveUsersReport_report_search}>
					<input
						type="text"
						id="searchUsersReport"
						name="searchUsersReport"
						value={searchVal}
						onChange={searchUsers}
						className={styles.AllActiveUsersReport_report_search_input}
						placeholder="Search by first/last, community, email, userID..."
					/>
				</div>
				<div className={styles.AllActiveUsersReport_report_entries}>
					<div className={styles.AllActiveUsersReport_report_entries_actions}>
						<ButtonSM
							title="Create a .csv file from the data"
							handleClick={createAndDownloadCSV}
							customStyles={customCSS.csv}
						>
							Download as .CSV
						</ButtonSM>
						<ButtonSM
							title="Only copies email addresses"
							handleClick={copyEmailsOnly}
							customStyles={customCSS.copyEmail}
						>
							Copy Emails ONLY
						</ButtonSM>
						<ButtonSM
							title="Copies ALL displayed users & their data"
							handleClick={copyAll}
							customStyles={customCSS.copyAll}
						>
							Copy All
						</ButtonSM>
					</div>
					<div className={styles.AllActiveUsersReport_report_entries_headings}>
						<div
							className={
								styles.AllActiveUsersReport_report_entries_headings_name
							}
						>
							Name
						</div>
						<div
							className={
								styles.AllActiveUsersReport_report_entries_headings_email
							}
						>
							Email
						</div>
						<div
							className={
								styles.AllActiveUsersReport_report_entries_headings_community
							}
						>
							Community
						</div>
						<div
							className={
								styles.AllActiveUsersReport_report_entries_headings_login
							}
						>
							Last Login
						</div>
						<div
							className={
								styles.AllActiveUsersReport_report_entries_headings_count
							}
						>
							Resident Count
						</div>
					</div>
					<div className={styles.AllActiveUsersReport_report_entries_users}>
						{/* SEARCH RESULTS */}
						{!isEmptyVal(searchVal) &&
							!isEmptyArray(clonedReportData) &&
							clonedReportData.map((entry, idx) => (
								<UserReportEntry
									key={`USER-REPORT-${idx}`}
									user={entry}
									currentUser={currentUser}
									entryNumber={idx + 1}
									facilityData={getUserFacilityData(entry, reportMetrics)}
								/>
							))}
						{/* RAW RESULTS */}
						{!isEmptyArray(reportData) &&
							isEmptyVal(searchVal) &&
							reportData.map((entry, idx) => (
								<UserReportEntry
									key={`USER-REPORT-${idx}`}
									user={entry}
									currentUser={currentUser}
									entryNumber={idx + 1}
									facilityData={getUserFacilityData(entry, reportMetrics)}
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AllActiveUsersReport;

AllActiveUsersReport.defaultProps = {};

AllActiveUsersReport.propTypes = {};

import React, { useState } from "react";
import styles from "../../css/facility/ImportResidents.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	convertAndFormatBytes,
	generateImportFileName,
	getFormData,
	mimeTypes,
} from "../../helpers/utils_files";
import {
	uploadResidentFile,
	uploadResidentFileXHR,
} from "../../helpers/utils_resident";
import { delay } from "../../helpers/utils_processing";
// components
import FileDropZone from "../shared/FileDropZone";
import ButtonSM from "../shared/ButtonSM";
import ImportDataExample from "./ImportDataExample";
import Divider from "../forms/Divider";
import SpinnerSM from "../shared/SpinnerSM";

const customCSS = {
	upload: {
		padding: ".7rem 1.5rem",
		fontSize: "1.6rem",
	},
};

const enableBtn = (fileUpload) => {
	return !isEmptyVal(fileUpload);
};

const ImportResidents = ({
	currentFacility = {},
	currentUser = {},
	dispatchAlert,
}) => {
	const [fileUpload, setFileUpload] = useState(null);
	const [isUploading, setIsUploading] = useState(false);

	// handles file input uploads & blob creation
	const handleFile = (e) => {
		const { files } = e.target;
		const target = files[0];
		// save file to state, set avatar 'src'
		setFileUpload(target);
	};
	// handles UI hover/drop state
	const handleDragOver = (e) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = "move";
	};
	// handles file drop upload & blob creation
	const handleFileDrop = (e) => {
		e.preventDefault();
		const { files } = e.dataTransfer;
		const target = files[0];
		setFileUpload(target);
	};

	// creates file name, fires off upload file request
	const importResidentFile = async () => {
		const { token } = currentUser;
		const fileName = `ResidentImport.csv`;
		await delay(1800);
		const wasUploaded = await uploadResidentFile(token, fileName, fileUpload);

		if (wasUploaded) {
			setIsUploading(false);
			return dispatchAlert("SUCCESS", {
				heading: `File was uploaded!!`,
				subheading: `Allow time for the data to be processed.`,
			});
		} else {
			setIsUploading(false);
			return dispatchAlert("ERROR", {
				heading: `There was an error`,
				subheading: `Please try back later!`,
			});
		}
	};

	

	return (
		<div className={styles.ImportResidents}>
			<div className={styles.ImportResidents_header}>
				<div className={styles.ImportResidents_header_title}>
					Import Residents File
				</div>
				<p className={styles.ImportResidents_header_desc}>
					{/* Accepts: .csv, .xlsx, .xls */}
					Accepts: .csv only
				</p>
			</div>
			<FileDropZone
				name="residentFile"
				id="residentFile"
				label="Choose File"
				handleFile={handleFile}
				handleDragOver={handleDragOver}
				handleFileDrop={handleFileDrop}
				hasFile={!isEmptyVal(fileUpload)}
				multiple={false}
				accept={`${mimeTypes.xls}, ${mimeTypes.xlsx}, ${mimeTypes.csv}`}
			/>
			{!isEmptyVal(fileUpload) && (
				<div className={styles.ImportResidents_info}>
					<div className={styles.ImportResidents_info_file}>
						Name: <b>{fileUpload?.name}</b>
					</div>
					<div className={styles.ImportResidents_info_file}>
						Size: <b>{convertAndFormatBytes(fileUpload?.size, "KB")}</b>
					</div>
				</div>
			)}
			<div className={styles.ImportResidents_actions}>
				<ButtonSM
					handleClick={(e) => {
						setIsUploading(true);
						importResidentFile();
					}}
					customStyles={customCSS.upload}
					isDisabled={!enableBtn(fileUpload) || isUploading}
				>
					{isUploading ? "Importing..." : "Import File"}
					{isUploading && <SpinnerSM color="#ffffff" />}
				</ButtonSM>
			</div>

			<Divider />

			<div className={styles.ImportResidents_extra}>
				<div className={styles.ImportResidents_header}>
					<div className={styles.ImportResidents_header_title}>
						Download Import Template
					</div>
					<p className={styles.ImportResidents_header_desc}>
						Click to download the pre-formatted Excel template to be sent to the
						customer to populate.
					</p>
				</div>
				<ImportDataExample />
			</div>
		</div>
	);
};

export default ImportResidents;

ImportResidents.defaultProps = {};

ImportResidents.propTypes = {};

const endpoints = {
	generic: {
		count: "Data/Count",
		get: "Data/Get",
		get2: "Data/Get2",
		delete: "Data/Delete",
		execute: "Data/Execute",
		insert: "Data/Insert",
		save: "Data/Save",
		update: "Data/Update",
	},
	auth: {
		login: "Security/Login",
		logout: "Security/Logout",
		loginStatus: "Security/SecurityTokenValid",
		validateToken: "Security/SecurityTokenValid",
		refreshToken: "Security/SecurityTokenRefresh",
		sessionDetails: "Security/GetSecurityTokenDetail",
		userAccessByID: "Security/GetUserAccess",
		userAccessByEmail: "Security/GetUserAccessByEmail",
	},
	security: {
		resetType: {
			save: "Security/SetUserPasswordRules",
			get: "Security/GetUserPasswordRules",
		},
		questions: {
			get: "ALADVSystem/GetSecurityQuestion",
			get2: "ALADVSystem/GetSecurityQuestion2",
			update: "ALADVSystem/UpdateSecurityQuestion",
			create: "Security/CreateUserSecurityQuestionAndAnswers",
			saveUserAnswer: "Security/SetUserSecurityAnswer",
			validate: {
				questionExists: "Security/ValidateSecurityQuestion",
				userQuestion: "Security/ValidateUserSecurityQuestion",
				userAnswer: "Security/ValidateUserSecurityAnswer",
			},
		},
		info: {
			get: "Security/GetUserInformation",
			getFacilitySecurityInfo: "Security/GetFacilityUserInformation",
		},
		userLogin: {
			getLogin: "ALADVSystem/GetUserLogin2",
			createNew: "Security/CreateUserLogin",
			validate: "Security/ValidateUserLoginExists",
			checkPasswordStrength: "Security/IsStrongPassword",
			suspendLogin: "Security/SetUserLoginSuspend",
			unsuspendLogin: "Security/UnsetUserLoginSuspend",
		},
		userProfile: {
			createNew: "Security/CreateUserProfile",
			get: "ALADVSystem/GetUserProfile",
			save: "ALADVSystem/SaveUserProfile", // only updates the main 'UserProfile' record data
			update: {
				phone: "ALADVSystem/SaveUserPhone",
				email: "ALADVSystem/SaveUserEmail",
				avatar: "ALADVSystem/SaveUserAvatar",
				alert: "ALADVSystem/SaveUserAlert",
				userTitle: "ALADVSystem/SaveUserTitle",
				userType: "ALADVSystem/SaveUserType",
			},
		},
		userType: {
			get: "ALADVSystem/GetUserType",
			get2: "ALADVSystem/GetUserType2",
			save: "ALADVSystem/SaveUserType",
		},
		userTitle: {
			get: "ALADVSystem/GetUserTitle",
			get2: "ALADVSystem/GetUserTitle2",
			save: "ALADVSystem/SaveUserTitle",
			create: "ALADVSystem/SaveUserTitle",
		},
		lockouts: {
			profile: {
				lock: "Security/SetUserProfileLockout",
				unlock: "Security/UnsetUserProfileLockout",
			},
			login: {
				lock: "Security/SetUserLoginLockout",
				unlock: "Security/UnsetUserLoginLockout",
				suspend: "Security/SetUserLoginSuspend",
				unsuspend: "Security/UnsetUserLoginSuspend",
			},
			otp: {
				isOtp: "Security/IsOtp",
				generate: "Security/GenerateOTP",
				generateAndSend: "Security/GenerateAndSendOTP",
			},
			summary: {
				getSummary: "Security/GetUserLockoutSummary",
				getLoginAttempts: "Security/GetFailedLoginAttempts",
			},
			confirmation: {
				sendConfirmationEmail: "Security/SendConfirmationEmail",
				registerConfirmationEmail: "Security/RegisterConfirmationEmail",
			},
		},
		facilityAccess: {
			getAccessList: "Facility/GetFacilityAccess", // returns list of facilityIDs
			registerAccess: "Facility/RegisterFacilityAccess", // sets access w/ custom array
			grantAccess: "Facility/GrantFacilityAccess",
			denyAccess: "Facility/DeniedFacilityAccess",
		},
	},
	roles: {
		get: "ALADVSystem/GetRole",
		get2: "ALADVSystem/GetRole2",
		save: "ALADVSystem/SaveRole",
		saveMany: "ALADVSystem/SaveRoleMany",
		update: "ALADVSystem/UpdateRole",
		updateMany: "ALADVSystem/UpdateRoleMany",
	},
	roleTypes: {
		get: "ALADVSystem/GetRoleType",
		get2: "ALADVSystem/GetRoleType2",
		save: "ALADVSystem/SaveRoleType",
		saveMany: "ALADVSystem/SaveRoleTypeMany",
		update: "ALADVSystem/UpdateRoleType",
		updateMany: "ALADVSystem/UpdateRoleTypeMany",
	},
	roleGroups: {
		get: "ALADVSystem/GetRoleGroup",
		get2: "ALADVSystem/GetRoleGroup2",
		save: "ALADVSystem/SaveRoleGroup",
		saveMany: "ALADVSystem/SaveRoleGroupMany",
		update: "ALADVSystem/UpdateRoleGroup",
		updateMany: "ALADVSystem/UpdateRoleGroupMany",
	},
	roleGroupTypes: {
		get: "ALADVSystem/GetRoleGroupType",
		get2: "ALADVSystem/GetRoleGroupType2",
		save: "ALADVSystem/SaveRoleGroupType",
		saveMany: "ALADVSystem/SaveRoleGroupTypeMany",
		update: "ALADVSystem/UpdateRoleGroupType",
		updateMany: "ALADVSystem/UpdateRoleGroupTypeMany",
	},
	user: {
		getAllUsers: "Security/GetAllUsersByUserEmail",
		getProfile: "Security/GetUserProfile",
		getProfileByEmail: "Security/GetUserProfileByEmail",
		getUser: {
			get: "Data/Get2",
			search: "Data/Get2",
		},
		create: {
			user: "Security/CreateUser", // creates 'ADVUSER' NOT new user infa
			newUserProfile: "Security/CreateUserProfile",
			newUserLogin: "Security/CreateUserLogin",
			newUserRecords: "Security/CreateUserProfileAndLogin",
			userAdmin: "Security/CreateUserAdmin",
			facilityAdmin: "Security/CreateUserFacilityAdmin",
		},
		edit: {
			user: "Advantage/SaveAdvUser",
			users: "Advantage/SaveAdvUserMany",
			changePassword: "Security/ChangeUserPassword",
			disableUser: "Advantage/SaveAdvUser",
			giveRegionalAccess: "Advantage/RoutineProcAdminAddRegional",
		},
		update: {
			user: "Advantage/UpdateAdvUser",
			users: "Advantage/UpdateAdvUserMany",
			userType: {
				asAdmin: "Security/SetUserTypeToAdministrator",
				asContractor: "Security/SetUserTypeToContractor",
				asFacilityAdmin: "Security/SetUserTypeToFacilityAdministrator",
				asManager: "Security/SetUserTypeToManager",
				asStaff: "Security/SetUserTypeToStaff",
				asSuperUser: "Security/SetUserTypeToSuperUser",
			},
		},
		security: {
			changePassword: "Security/ChangeUserPassword",
		},
		delete: {
			user: "Advantage/DeleteAdvUser",
		},
		access: {
			getFacilityList: "Community/GetCommunitiesByUserEmail",
			getFacilityList2: "Advantage/GetAdvUserCommunities2",
			grantAccess: "Advantage/SaveAdvUserCommunities",
			grantAccessMany: "Advantage/SaveAdvUserCommunitiesMany",
			removeAccess: "Advantage/DeleteAdvUserCommunities", // removes a single record/facility
		},
		roles: {
			get: "ALADVSystem/GetRole",
			get2: "ALADVSystem/GetRole2",
			save: "ALADVSystem/SaveRole",
			saveMany: "ALADVSystem/SaveRoleMany",
			update: "ALADVSystem/UpdateRole",
			updateMany: "ALADVSystem/UpdateRoleMany",
		},
		roleTypes: {
			get: "ALADVSystem/GetRoleType",
			get2: "ALADVSystem/GetRoleType2",
			save: "ALADVSystem/SaveRoleType",
			saveMany: "ALADVSystem/SaveRoleTypeMany",
			update: "ALADVSystem/UpdateRoleType",
			updateMany: "ALADVSystem/UpdateRoleTypeMany",
		},
		roleGroups: {
			get: "ALADVSystem/GetRoleGroup",
			get2: "ALADVSystem/GetRoleGroup2",
			save: "ALADVSystem/SaveRoleGroup",
			saveMany: "ALADVSystem/SaveRoleGroupMany",
			update: "ALADVSystem/UpdateRoleGroup",
			updateMany: "ALADVSystem/UpdateRoleGroupMany",
		},
		roleGroupTypes: {
			get: "ALADVSystem/GetRoleGroupType",
			get2: "ALADVSystem/GetRoleGroupType2",
			save: "ALADVSystem/SaveRoleGroupType",
			saveMany: "ALADVSystem/SaveRoleGroupTypeMany",
			update: "ALADVSystem/UpdateRoleGroupType",
			updateMany: "ALADVSystem/UpdateRoleGroupTypeMany",
		},
	},
	facility: {
		getFacilityData1: "ALADVSystem/GetFacility",
		getFacilityData: "Advantage/GetFacility2",
		getCategories: "Advantage/GetAssessmentCategory",
		getShifts: "Advantage/GetAssessmentShift",
		getUsers: "Advantage/GetAdvUser2",
		getResidents: "Resident/GetResidentsByFacility",
		getFacilities: {
			byUser: "Community/GetCommunitiesByUser",
			byUserEmail: "Community/GetCommunitiesByUserEmail",
			bySuspended: "Facility/GetSuspendedFacilities",
		},
		exceptions: {
			getExceptionType: "Advantage/GetAssessmentFacilityException",
			saveExceptionType: "Advantage/SaveAssessmentFacilityException",
			saveExceptionTypeMany: "Advantage/SaveAssessmentFacilityExceptionMany",
			deleteExceptionType: "Advantage/DeleteAssessmentFacilityException",
			deleteExceptionTypeMany:
				"Advantage/DeleteAssessmentFacilityExceptionMany",
		},
		cancellations: {
			getCancellationType: "Advantage/GetAssessmentFacilityCancellation",
			saveCancellationType: "Advantage/SaveAssessmentFacilityCancellation",
			saveCancellationTypeMany:
				"Advantage/SaveAssessmentFacilityCancellationMany",
			deleteCancellationType: "Advantage/DeleteAssessmentFacilityCancellation",
			deleteCancellationTypeMany:
				"Advantage/DeleteAssessmentFacilityCancellationMany",
		},
		notifications: {
			incidents: "Notification/GetIncidentNotificationEmailList",
			assessments: "Notification/GetAssessmentChangeNotificationEmailList",
			adminLockout: "Notification/GetAdminLockoutNotificationEmailList",
			removeRecipient: {
				fromIncidents: "Notification/DeleteIncidentNotificationEmailList",
				fromAssessments:
					"Notification/DeleteAssessmentChangeNotificationEmailList",
				fromLockouts: "Notification/DeleteAdminLockoutNotificationEmailList",
			},
			updateList: {
				incidents: "Notification/UpdateIncidentNotificationEmailList",
				assessments: "Notification/UpdateAssessmentChangeNotificationEmailList",
				lockouts: "Notification/UpdateAdminLockoutNotificationEmailList",
			},
			deleteList: {
				incidents: "Notification/DeleteIncidentNotificationEmailList",
				assessments: "Notification/DeleteAssessmentChangeNotificationEmailList",
				lockouts: "Notification/DeleteAdminLockoutNotificationEmailList",
			},
		},
		update: {
			facilityInfo: "Advantage/UpdateFacility",
			facilityName: "Advantage/UpdateFacility",
			appAccess: "Security/SetFacilityApplicationAccess",
		},
		migrateUsers: "Security/MigrateFacilityUsers",
		migrationStatus: "ALADVSystem/GetSettingFacility",
		migrationStatus2: "ALADVSystem/GetSettingFacility2",
		disassociate: {
			facilityFromParent: "Facility/DisassociateFacilitiesFromParent",
			facilityFromUsers: "Facility/DisassociateFacilitiesFromUsers",
		},
		// new APIs
		unsuspend: {
			facilityAccount: "Facility/UnSuspendFacilityAccount",
		},
		suspend: {
			facilityAccount: "Facility/SuspendFacilityAccount",
			getReasons: "Advantage/GetSuspendReasonType",
			getSuspendedFacilities: "Facility/GetSuspendedFacilities",
			getSuspendedUsers: "Security/GetSuspendedUsers",
			getLockoutUsers: "Security/GetLockoutUsers",
			getLockoutUserProfiles: "Security/GetLockoutUserProfiles",
		},
		moveFacility: {
			childToParent: "Facility/MoveChildFacilityToParentFacility",
			independentToParent: "Facility/MoveIndependentFacilityToParentFacility",
			childToIndependent: "Facility/MoveChildFacilityToIndependentFacility",
		},
		create: {
			newAccount: "Facility/CreateFacility",
			newAdmin: "Security/CreateUserAdmin",
		},
		activity: {
			lastLogins: "Security/GetLastLogins",
		},
		// task scheduler/generator
		scheduler: {
			runTonight: "Scheduling/ScheduleAdhocTaskGeneration",
		},
		// facility close-out API(s)
		closeOut: {
			exportData: "Facility/ExportFacilityDataBkg",
			exportTrackerData: "Facility/ExportFacilityTrackerDataBkg",
		},
		facilityTypes: {
			getParents: "Dao/Facility/GetParentFacilities",
			getChilds: "Dao/Facility/GetChildFacilities",
			getChildAndParent: "Dao/Facility/GetParentAndChildFacilities",
		},
	},
	residentData: {
		getPhotos: "Advantage/GetResidentPhotos",
		getSummary: "Advantage/GetSummary",
		getAssessment: "Resident/GetResidentAssessment",
		getContacts: "Advantage/GetContacts",
		getProfile: "Resident/GetResidentProfile",
		getLOA: "Advantage/GetLeaveOfAbsence",
		getMeds: "Advantage/GetMedications",
		getInventory: "Advantage/GetResidentInventory",
		getResidentBM: "Advantage/GetResidentBowelMovements",
		getResidentWeight: "Advantage/GetResidentWeight",
		transferResident: {
			toFacility: "Resident/TransferResidentToFacility",
		},
		forTracker: {
			byDay: "Resident/GetResidentDayForAdvantageTracker",
			byWeek: "Resident/GetResidentWeekForAdvantageTracker",
			byDayMaster: "Resident/GetResidentDayAssessmentMaster",
			byWeekMaster: "Resident/GetResidentWeekAssessmentMaster",
			byDayDetails: "Resident/GetResidentDayAssessmentDetails",
			byWeekDetails: "Resident/GetResidentDayAssessmentDetails",
			purgeByDay: "Resident/DeleteResidentTrackingTasks",
		},
		// for adl shift schedule (ie shifts per adl)
		adlSchedule: {
			getSchedule: "Advantage/GetAssessmentResidentAdlShift",
			saveScheduleMany: "Resident/UpdateResidentsAdlShifts",
			getAdlShiftChanges: "Facility/GetFacilityAdlShifts",
		},
	},
	uploads: {
		upload: "Upload/PutFile",
		uploadMany: "Upload/PutFileMany",
		saveFileRegistry: "Upload/SaveFileRegistry",
		saveFileRegistryMany: "Upload/SaveFileRegistryMany",
		uploadResidentFile: "Upload/ImportResidentFile",
	},
	downloads: {
		getFile: "Download/GetFile",
		getFileMany: "Download/GetFileMany",
		getFileAndZip: "Download/GetFileAndZip",
		getFileAndZipMany: "Download/GetFileManyAndZip",
		getFileRegistry: {
			byUser: "Download/GetFileRegistryByUser",
			byResident: "Download/GetFileRegistryByResident",
			byFacility: "Download/GetFileRegistryByFacility",
			byMeta: "Download/GetFileRegistryByMeta",
		},
	},
	lockouts: {
		profile: {
			lock: "Security/SetUserProfileLockout",
			unlock: "Security/UnsetUserProfileLockout",
		},
		login: {
			lock: "Security/SetUserLoginLockout",
			unlock: "Security/UnsetUserLoginLockout",
			suspend: "Security/SetUserLoginSuspend",
			unsuspend: "Security/UnsetUserLoginSuspend",
		},
		otp: {
			isOtp: "Security/IsOtp",
			generate: "Security/GenerateOTP",
			generateAndSend: "Security/GenerateAndSendOTP",
		},
		summary: {
			getSummary: "Security/GetUserLockoutSummary",
			getLoginAttempts: "Security/GetFailedLoginAttempts",
		},
		confirmation: {
			sendConfirmationEmail: "Security/SendConfirmationEmail",
			registerConfirmationEmail: "Security/RegisterConfirmationEmail",
		},
	},
	migrateToNewInfra: {
		allFacilities: "Security/MigrateAllFacilityUsers",
	},
	settings: {
		getSSO: "Advantage/GetAdministrativeDisplaySettings",
		getSSO2: "Advantage/GetAdministrativeDisplaySettings2",
		// toggleSSO: "Advantage/SaveAdministrativeDisplaySettings",
		toggleSSO: "Advantage/UpdateAdministrativeDisplaySettings",
		anySetting: "Advantage/UpdateAdministrativeDisplaySettings",
		getSetting: "Advantage/GetAdministrativeDisplaySettings",
		getSetting2: "Advantage/GetAdministrativeDisplaySettings2",
		clearCache: "Security/ClearCache",
	},
	// LEVELS OF CARE
	loc: {
		get: "Facility/GetLevelsOfCare",
		save: "Facility/SaveLevelsOfCare",
		saveSettings: "Advantage/UpdateFacility",
		isEditable: "Facility/IsEditableLevelsOfCare",
		reset: "Facility/ResetLevelsOfCare",
		flagSettings: "Facility/SetLevelsOfCareFlags",
		lockAll: "Facility/LockLevelsOfCareChildFacilities",
		unlockAll: "Facility/UnLockLevelsOfCareChildFacilities",
	},
	locTemplates: {
		create: {
			newTemplate: "Facility/CreateLevelsOfCareTemplate",
			newTemplateByDict: "Facility/CreateLevelsOfCareTemplateByDict",
		},
		get: {
			facilityTemplateNames: "Facility/GetLevelsOfCareTemplateHeaders",
			facilityTemplates: "Facility/GetLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/GetLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/GetLevelsOfCareTemplateHeaderAndStructure",
		},
		update: {
			facilityTemplateNames: "Facility/UpdateLevelsOfCareTemplateHeaders",
			facilityTemplates:
				"Facility/UpdateLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/UpdateLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/UpdateLevelsOfCareTemplateHeaderAndStructure",
		},
		save: {
			facilityTemplateNames: "Facility/SaveLevelsOfCareTemplateHeaders",
			facilityTemplates:
				"Facility/SaveLevelsOfCareTemplateHeadersAndStructures",
			facilityTemplate: "Facility/SaveLevelsOfCareTemplate",
			facilityTemplateByID:
				"Facility/SaveLevelsOfCareTemplateHeaderAndStructure",
		},
		saveBy: {
			facilityTemplate: "Facility/SaveLevelsOfCareByTemplate",
		},
		delete: {
			facilityTemplate: "Facility/DeleteLevelsOfCareTemplate",
		},
		validate: {
			levels: "Facility/ValidateLevelsOfCare",
		},
	},
	// VENDORS DATA MAPPING
	vendors: {
		// fetches
		getVendor: "ALADVSystem/GetVendor2", // single vendor
		getVendors: "ALADVSystem/GetVendor", // vendors list
		getVendorDataMaps: "ALADVSystem/GetVendorDataMap",
		getVendorDataMaps2: "ALADVSystem/GetVendorDataMap2",
		// save/updates
		saveMapping: "ALADVSystem/SaveVendorDataMap",
		updateMapping: "ALADVSystem/UpdateVendorDataMap",
		removeMapping: "ALADVSystem/UpdateVendorDataMap",
		deactivateMapping: "ALADVSystem/DeactivateVendorDataMap",
	},
	emar: {
		sso2: "Interfaces/EmarSingleSignOn",
		facility: {
			addFacility: "Interfaces/EmarAddFacility",
			removeFacility: "Interfaces/EmarRemoveFacility",
			updateFacility: "Interfaces/EmarUpdateFacility",
			enableFacility: "Interfaces/EmarEnableFacility",
			disableFacility: "Interfaces/EmarDisableFacility",
			checkAccess: "Interfaces/EmarAccessibleForFacility",
			checkAllUsersAccess: "Facility/DoesAllFacilityUsersHaveAccess",
			enableAllUsers: "Facility/AssignAppToFacilityUsers",
			disableAllUsers: "Facility/UnAssignAppToFacilityUsers",
		},
		user: {
			addUser: "Interfaces/EmarAddUser",
			removeUser: "Interfaces/EmarRemoveUser",
			updateUser: "Interfaces/EmarUpdateUser",
			enableUser: "Interfaces/EmarEnableUser",
			disableUser: "Interfaces/EmarDisableUser",
			checkAccess: "Interfaces/EmarAccessibleForUser",
		},
		resident: {
			addResident: "Interfaces/AddResident",
			removeResident: "Interfaces/RemoveResident",
			updateResident: "Interfaces/UpdateResident",
			enableResident: "Interfaces/EnableResident",
			disableResident: "Interfaces/DisableResident",
			checkAccess: "Interfaces/EmarAccessibleForResident",
		},
	},
	// !!! DONT USER THE FOLLOWING APIS !!! //
	chartmeds: {
		sso: "Interfaces/ChartMedsInterface/SingleSignOn",
		facility: {
			addFacility: "Interfaces/ChartMedsInterface/AddFacility",
			removeFacility: "Interfaces/ChartMedsInterface/RemoveFacility",
			updateFacility: "Interfaces/ChartMedsInterface/UpdateFacility",
			enableFacility: "Interfaces/ChartMedsInterface/EnableFacility",
			disableFacility: "Interfaces/ChartMedsInterface/DisableFacility",
		},
		user: {
			addUser: "Interfaces/ChartMedsInterface/AddUser",
			removeUser: "Interfaces/ChartMedsInterface/RemoveUser",
			updateUser: "Interfaces/ChartMedsInterface/UpdateUser",
			enableUser: "Interfaces/ChartMedsInterface/EnableUser",
			disableUser: "Interfaces/ChartMedsInterface/DisableUser",
		},
		resident: {
			addResident: "Interfaces/ChartMedsInterface/AddResident",
			removeResident: "Interfaces/ChartMedsInterface/RemoveResident",
			updateResident: "Interfaces/ChartMedsInterface/UpdateResident",
			enableResident: "Interfaces/ChartMedsInterface/EnableResident",
			disableResident: "Interfaces/ChartMedsInterface/DisableResident",
		},
	},
	interfaces: {
		chartMeds: {},
		accuflo: {},
		eldermark: {},
	},
	// internal reports APIs
	reports: {
		getActiveUsersList: "Security/GetUsers",
		getActiveCommunitiesList: "Facility/GetFacilities",
	},
	// SERVICE BANNERS
	banners: {
		// USER-LEVEL/USER-FACING
		getFacilityBanners: "Facility/GetFacilityBanners",
		// FOR INTERNAL PORTAL: DISPLAYS ACTIVE BANNERS
		getCurrentBanners: "Advantage/GetFacilityBanner",
		getCurrentBanners2: "Advantage/GetFacilityBanner2",
		getAllBanners: "Advantage/GetFacilityBanner2",
		getBannersByFacility: "Advantage/GetFacilityBanner2",
		saveNewBanner: "Advantage/SaveFacilityBanner",
		updateBanner: "Advantage/SaveFacilityBanner",
		deleteBanner: "Advantage/DeleteFacilityBanner",
		deactivateBanner: "Advantage/DeactivateFacilityBannerById",
	},
	acknowledge: {
		byUserID: "Security/AcknowledgeBy",
		userLogin: "Security/UserLoginAcknowledge",
		getUserLoginAcknowledge: "ALADVSystem/GetUserLoginAcknowledge",
		getUserLoginAcknowledge2: "ALADVSystem/GetUserLoginAcknowledge2",
		saveUserLoginAcknowledge: "ALADVSystem/SaveUserLoginAcknowledge",
		saveUserLoginAcknowledgeMany: "ALADVSystem/SaveUserLoginAcknowledgeMany",
		updateUserLoginAcknowledge: "ALADVSystem/UpdateUserLoginAcknowledge",
	},
	stax: {
		facility: {
			addFacility: "StaxInterface/AddFacility",
			removeFacility: "StaxInterface/RemoveFacility",
			updateFacility: "StaxInterface/UpdateFacility",
			enableFacility: "StaxInterface/EnableFacility",
			disableFacility: "StaxInterface/DisableFacility",
			// 'Interfaces' Module
			getAppID: "Interfaces/StaxApplicationIdForFacility",
			isAccessible: "Interfaces/StaxAccessibleForFacility",
			assignTo: "Interfaces/StaxAssignToFacility",
			addFacility2: "Interfaces/StaxAddFacility",
		},
		resident: {
			addResident: "StaxInterface/AddResident",
			addResident2: "Interfaces/StaxAddResident",
			removeResident: "StaxInterface/RemoveResident",
			updateResident: "StaxInterface/UpdateResident",
			enableResident: "StaxInterface/EnableResident",
			disableResident: "StaxInterface/DisableResident",
			transferResident: "StaxInterface/TransferResident",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForResident",
		},
		user: {
			addUser: "StaxInterface/AddUser",
			removeUser: "StaxInterface/RemoveUser",
			updateUser: "StaxInterface/UpdateUser",
			enableUser: "StaxInterface/EnableUser",
			disableUser: "StaxInterface/DisableUser",
			// 'Interfaces' Module
			isAccessible: "Interfaces/StaxAccessibleForUser",
			assignTo: "Interfaces/StaxAssignToUser",
			addUser2: "Interfaces/StaxAddUser",
			removeUser2: "Interfaces/StaxRemoveUser",
			updateUser2: "Interfaces/StaxUpdateUser",
		},
		sso: {
			authSSO: "StaxInterface/SingleSignOn",
		},
	},
};

const {
	generic,
	auth,
	security,
	roles,
	roleTypes,
	roleGroups,
	roleGroupTypes,
	user,
	residentData,
	facility,
	facility: { getFacilities, facilityTypes },
	lockouts,
	uploads,
	downloads,
	downloads: { getFileRegistry },
	// warning!! be careful with this API
	migrateToNewInfra,
	settings,
	loc,
	locTemplates,
	vendors,
	emar,
	chartmeds,
	reports,
	banners,
	acknowledge,
	stax,
} = endpoints;

export {
	generic,
	auth,
	security,
	roles,
	roleTypes,
	roleGroups,
	roleGroupTypes,
	residentData,
	user,
	facility,
	getFacilities,
	lockouts,
	uploads,
	downloads,
	migrateToNewInfra,
	settings,
	loc,
	locTemplates,
	getFileRegistry,
	facilityTypes,
	vendors,
	emar,
	chartmeds,
	reports,
	banners,
	acknowledge,
	stax,
};

import React, { useState } from "react";
import styles from "../../css/admin/UserTypesController.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { red } from "../../helpers/utils_styles";
// components
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getTypeNamesFromSelections,
	updateUserTypesMany,
} from "../../helpers/utils_createAdmin";
import {
	matchUserFromLogins,
	matchUserFromNames,
	matchUsernameFromList,
} from "../../helpers/utils_user";

const customCSS = {
	cancel: {
		padding: "1rem 1.8rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
	},
	save: {
		padding: "1rem 1.8rem",
		backgroundColor: red[600],
		fontSize: "1.6rem",
	},
	types: {
		width: "40rem",
	},
};

const formatUsers = (users) => {
	if (isEmptyArray(users)) return [];
	return users.map((user) => {
		const { firstName, lastName, email } = user;
		const userStr = `${lastName}, ${firstName} ~ ${email}`;
		return userStr;
	});
};

// checks for selections of 'permissioned' and 'non-permissioned' types
const checkSelections = (vals) => {
	const {
		enableAdminType,
		enableSuperUserType,
		enableFacilityAdminType,
		// non-admin typesType:
		enableContractorType,
		enableManagerType,
		enableStaffType,
	} = vals;

	const hasPerm =
		enableAdminType || enableSuperUserType || enableFacilityAdminType;
	const hasNonPerm =
		enableContractorType || enableManagerType || enableStaffType;

	return {
		hasPermissioned: hasPerm,
		hasNonPermissioned: hasNonPerm,
	};
};

const enableBtn = (selectedUser, vals = {}) => {
	const { hasPermissioned } = checkSelections(vals);
	const hasUser = !isEmptyVal(selectedUser);

	return hasPermissioned && hasUser;
};

const UserTypesController = ({
	users = [],
	currentFacility = {},
	currentUser = {},
	targetUser = {},
	dispatchAlert,
}) => {
	const { formState, handleChange, handleCheckbox, handleReset } = useForm({
		enableAdminType: false,
		enableSuperUserType: false,
		enableFacilityAdminType: false,
		// non-admin typesType:
		enableContractorType: false,
		enableManagerType: false,
		enableStaffType: false,
	});
	const { values } = formState;
	const [selectedUser, setSelectedUser] = useState("");

	const handleUserSelection = (name, user) => {
		setSelectedUser(user);
	};

	const saveTypeChanges = async () => {
		const { token } = currentUser;
		// extract 'username/email' from selection & get 'userID' from matching record
		const username = selectedUser.split(" ~ ")[1];
		const userMatch = matchUsernameFromList(username, users);
		const typesList = getTypeNamesFromSelections(values);

		
		
		
		

		// const wasUpdated = true;
		const wasUpdated = await updateUserTypesMany(
			token,
			userMatch.userID,
			typesList
		);

		if (wasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `User type(s) were saved!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong!`,
				subheading: `Try again later, please.`,
			});
		}
	};

	const cancelTypeChanges = (e) => {
		handleReset(e);
	};

	return (
		<div className={styles.UserTypesController}>
			<section className={styles.UserTypesController_admin}>
				<div className={styles.UserTypesController_admin_selector}>
					<CustomDropdown
						name="selectedUser"
						id="selectedUser"
						label="Select User to Update"
						options={formatUsers(users)}
						selection={selectedUser}
						setSelection={handleUserSelection}
						customStyles={customCSS.types}
					/>
				</div>
				<div className={styles.UserTypesController_admin_title}>
					Permissioned User Types
				</div>

				<div className={styles.UserTypesController_option}>
					<CustomCheckbox
						label="Enable Admin"
						name="enableAdminType"
						id="enableAdminType"
						val={values.enableAdminType}
						handleCheckbox={handleCheckbox}
						isDisabled={checkSelections(values).hasNonPermissioned}
					/>
				</div>
				<div className={styles.UserTypesController_option}>
					<CustomCheckbox
						label="Enable Super User"
						name="enableSuperUserType"
						id="enableSuperUserType"
						val={values.enableSuperUserType}
						handleCheckbox={handleCheckbox}
						isDisabled={checkSelections(values).hasNonPermissioned}
					/>
				</div>
				<div className={styles.UserTypesController_option}>
					<CustomCheckbox
						label="Enable Facility Admin"
						name="enableFacilityAdminType"
						id="enableFacilityAdminType"
						val={values.enableFacilityAdminType}
						handleCheckbox={handleCheckbox}
						isDisabled={checkSelections(values).hasNonPermissioned}
					/>
				</div>
			</section>

			<section className={styles.UserTypesController_actions}>
				<ButtonSM
					handleClick={cancelTypeChanges}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={!enableBtn(selectedUser, values)}
					customStyles={customCSS.save}
					handleClick={saveTypeChanges}
				>
					Save Changes
				</ButtonSM>
			</section>
		</div>
	);
};

export default UserTypesController;

UserTypesController.defaultProps = {};

UserTypesController.propTypes = {};

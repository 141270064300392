import React, { useState } from "react";
import styles from "../../css/disassociate/DisassociateFacilityFromUsers.module.scss";
import { PropTypes } from "prop-types";
import MultiSelect from "../shared/MultiSelect";
import ButtonSM from "../shared/ButtonSM";
import { red } from "../../helpers/utils_styles";
import { isEmptyArray } from "../../helpers/utils_types";

// STEPS:
// 1. Select 1 or more facility(s) for which to have their users removed/disassociated

const customCSS = {
	input: {
		backgroundColor: "#ffffff",
	},
	btn: {
		backgroundColor: red[600],
		color: "#ffffff",
		padding: ".8rem 1.3rem",
		fontSize: "1.6rem",
	},
};

const DisassociateFacilityFromUsers = ({
	facilities = [],
	parentsMap = {},
	saveHandler,
}) => {
	// const { names } = parentsMap;
	const names = facilities;
	const [selectedFacilities, setSelectedFacilities] = useState([]);

	const handleSelections = (selected) => {
		setSelectedFacilities(selected);
	};

	const handleSave = (e) => {
		saveHandler(selectedFacilities);
	};

	return (
		<div className={styles.DisassociateFacilityFromUsers}>
			<div className={styles.DisassociateFacilityFromUsers_desc}>
				Remove the association of one or more communities and their users.
			</div>
			<MultiSelect
				key={`DISASSOCIATE-USERS-${names?.length}`}
				initialSelections={[]}
				options={names}
				disabledOptions={[]}
				handleMultiSelect={handleSelections}
				customStyles={customCSS.input}
			/>
			<div className={styles.DisassociateFacilityFromUsers_summary}>
				Running this action will affect <b>{selectedFacilities?.length ?? 0}</b>{" "}
				community(s).
			</div>
			<div className={styles.DisassociateFacilityFromUsers_warning}>
				Warning: This action MAY change or alter this community's billing
				situation. It will remove the selected communities' users from the
				facility.
			</div>
			<div className={styles.DisassociateFacilityFromUsers_actions}>
				<ButtonSM
					isDisabled={isEmptyArray(selectedFacilities)}
					handleClick={handleSave}
					customStyles={customCSS.btn}
				>
					Disassociate Users From Communities
				</ButtonSM>
			</div>
		</div>
	);
};

export default DisassociateFacilityFromUsers;

DisassociateFacilityFromUsers.defaultProps = {};

DisassociateFacilityFromUsers.propTypes = {};

import React, { useRef, useEffect } from "react";
import styles from "../../css/facility/CreateAccountStatus.module.scss";
import sprite from "../../assets/icons/internal-portal.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
// components
import SuccessIndicator from "./SuccessIndicator";
import ErrorIndicator from "./ErrorIndicator";
import CopyText from "../forms/CopyText";

const customCSS = {
	text: {
		fontSize: "1.5rem",
	},
};

const SuccessUI = ({ newAccountID }) => {
	return (
		<div className={styles.SuccessUI}>
			<div className={styles.SuccessUI_success}>
				New Account was Created Successfully!!
			</div>
			<div className={styles.SuccessUI_newAccountID}>
				<b>FacilityID:</b>{" "}
				<CopyText
					text={newAccountID}
					msgTimeout={3000}
					customStyles={customCSS.text}
				/>
			</div>
		</div>
	);
};

const ErrorUI = ({ errorMsgs = [] }) => {
	return (
		<div className={styles.ErrorUI}>
			<div className={styles.ErrorUI_failed}>Account Creation Failed!!!</div>
			<div className={styles.ErrorUI_failedMsg}>Try again later, please.</div>
			<div className={styles.ErrorUI_errorMsg}>{errorMsgs?.[0] ?? ""}</div>
		</div>
	);
};

const CreateAccountStatus = ({
	newAccountID,
	errorMsgs = [],
	wasSuccessful = false,
	handleReset,
	closeModal,
}) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const wasEscaped = useKeyboardShortcut(["Escape"]);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside || wasEscaped) {
			closeModal();
		}

		return () => {
			isMounted = false;
		};
	}, [isOutside, wasEscaped, closeModal]);

	

	return (
		<div className={styles.CreateAccountStatus} ref={modalRef}>
			<div className={styles.CreateAccountStatus_close}>
				<svg
					className={styles.CreateAccountStatus_close_icon}
					onClick={closeModal}
				>
					<use xlinkHref={`${sprite}#icon-clear`}></use>
				</svg>
			</div>
			<div className={styles.CreateAccountStatus_status}>
				{wasSuccessful && (
					<SuccessIndicator>
						<SuccessUI newAccountID={newAccountID} />
					</SuccessIndicator>
				)}
				{!wasSuccessful && (
					<ErrorIndicator>
						<ErrorUI errorMsgs={errorMsgs} />
					</ErrorIndicator>
				)}
			</div>
			<div className={styles.CreateAccountStatus_actions}>
				<button
					type="button"
					onClick={handleReset}
					className={styles.CreateAccountStatus_actions_btn}
				>
					Close Window
				</button>
			</div>
		</div>
	);
};

export default CreateAccountStatus;

CreateAccountStatus.defaultProps = {};

CreateAccountStatus.propTypes = {};

import React, { useState } from "react";
import styles from "../../css/admin/MakeRegionalAdmin.module.scss";
import { PropTypes } from "prop-types";
import { red } from "../../helpers/utils_styles";
import {
	extractEmailAndMatch,
	formatUserList,
	giveRegionalAccessAndProcess,
} from "../../helpers/utils_user";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	getFacilityNames,
	matchFacilityByName,
} from "../../helpers/utils_facility";
// components
import ButtonSM from "../shared/ButtonSM";
import CustomDropdown from "../shared/CustomDropdown";

// REQUIREMENTS:
// - Two Dropdowns:
//    - 1st: with ALL available users
//    - 2nd: with ALL available facilities

const customCSS = {
	selectors: {
		width: "40rem",
	},
	cancel: {
		padding: "1.2rem 2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: ".5rem",
	},
	save: {
		padding: "1.2rem 2rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: red[600],
		color: "#ffffff",
	},
};

const disableBtn = (targetUser, targetFacility) => {
	const isEmpty = isEmptyVal(targetUser) || isEmptyVal(targetFacility);

	return isEmpty;
};

const MakeRegionalAdmin = ({
	globalState,
	currentFacility,
	currentUser,
	dispatchAlert,
}) => {
	const { facilities } = currentUser;
	const { users = [], userMaps = {} } = currentFacility;
	// target facility to provide 'regional' access for
	const [regionalFacility, setRegionalFacility] = useState("");
	// target user to give 'regional' access to
	const [userToMakeRegional, setUserToMakeRegional] = useState("");

	const handleFacility = (name, val) => {
		setRegionalFacility(val);
	};
	const handleUser = (name, val) => {
		setUserToMakeRegional(val);
	};

	// grants 'regional' access for a user to a given facility
	const provideRegionalAccess = async () => {
		const { token } = currentUser;
		const { namesMap } = userMaps;
		const targetUserID = extractEmailAndMatch(userToMakeRegional, namesMap);
		const facility = matchFacilityByName(regionalFacility, facilities);

		
		

		// const enabledRegional = false;
		// fires off request & processes/formats response
		const { wasSuccessful: enabledRegional, msg } =
			await giveRegionalAccessAndProcess(
				token,
				targetUserID,
				facility?.facilityID
			);

		if (enabledRegional) {
			setRegionalFacility("");
			setUserToMakeRegional("");
			return dispatchAlert("SUCCESS", {
				heading: `Regional Access Was Successful!`,
				subheading: `You've provided regional access for this user.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Update Failed!!!`,
				subheading: `${msg}`,
			});
		}
	};

	const cancelRegionalAccess = (e) => {
		setRegionalFacility("");
		setUserToMakeRegional("");
	};

	return (
		<div className={styles.MakeRegionalAdmin}>
			<header className={styles.MakeRegionalAdmin_header}>
				<h2 className={styles.MakeRegionalAdmin_header_title}>
					Provide Regional Admin Access
				</h2>
				<p className={styles.MakeRegionalAdmin_header_desc}>
					Give user regional access to reports etc.
				</p>
			</header>
			{isEmptyVal(currentFacility.facilityID) && (
				<div className={styles.MakeRegionalAdmin_notice}>
					Please load a facility above
				</div>
			)}
			<div className={styles.MakeRegionalAdmin_main}>
				{/* USER SELECTOR */}
				<div className={styles.MakeRegionalAdmin_main_selector}>
					<CustomDropdown
						key={`REGIONAL-OPTIONS-${users?.length}`}
						name="userToMakeRegional"
						id="userToMakeRegional"
						label="Choose User"
						selection={userToMakeRegional}
						setSelection={handleUser}
						options={[...formatUserList(users)]}
						customStyles={customCSS.selectors}
					/>
				</div>
				{/* REGIONAL FACILITY SELECTOR */}
				<div className={styles.MakeRegionalAdmin_main_selector}>
					<CustomDropdown
						name="regionalFacility"
						id="regionalFacility"
						label="Select Regional Facility To Give Access"
						selection={regionalFacility}
						setSelection={handleFacility}
						options={[...getFacilityNames(facilities)]}
						customStyles={customCSS.selectors}
					/>
				</div>
			</div>

			<div className={styles.MakeRegionalAdmin_main_actions}>
				<ButtonSM
					handleClick={cancelRegionalAccess}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={disableBtn(userToMakeRegional, regionalFacility)}
					handleClick={provideRegionalAccess}
					customStyles={customCSS.save}
				>
					Give Regional Access
				</ButtonSM>
			</div>
		</div>
	);
};

export default MakeRegionalAdmin;

MakeRegionalAdmin.defaultProps = {};

MakeRegionalAdmin.propTypes = {};

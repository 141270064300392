import React, { useState, useEffect } from "react";
import styles from "../../css/reports/CommunityActiveUsersReport.module.scss";
import { PropTypes } from "prop-types";
import { blue, green, pink, red } from "../../helpers/utils_styles";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getActiveCommunitiesList,
	getActiveUsersList,
} from "../../helpers/utils_reports";
import { groupBy, sortByAlphaAsc } from "../../helpers/utils_processing";
import {
	convertArrayDataToCSV,
	saveDataToCSV,
} from "../../helpers/utils_files";
import { format } from "date-fns";
// components
import UserReportEntry from "./UserReportEntry";
import ButtonSM from "../shared/ButtonSM";
import SpinnerSM from "../shared/SpinnerSM";
import CustomDropdown from "../shared/CustomDropdown";

const customCSS = {
	selector: {
		width: "40rem",
	},
	btn: {
		padding: ".8rem 1.4rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginBottom: "4rem",
	},
	searching: {
		color: red[600],
	},
	copyAll: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: blue[600],
	},
	copyEmail: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: green[600],
		marginRight: "1rem",
	},
	csv: {
		padding: ".5rem 1rem",
		fontSize: "1.5rem",
		fontWeight: "600",
		backgroundColor: pink[600],
		marginRight: "1rem",
	},
	commName: {
		color: red[600],
	},
};

const getCommunityFileName = (selectedFacility) => {
	// Remove the words 'At/at' and 'The/the' & insert '_' into spaces
	const removeAt = selectedFacility.replace(/At |at /g, "");
	const removeThe = removeAt.replace(/The |the /, "");
	const withUnderscores = removeThe.replace(/\s/g, "_");
	const community = withUnderscores.replace(/,/, "");

	const now = format(new Date(), "MM_DD_YYYY");
	const fileName = `${community}_Users_${now}.csv`;

	
	
	
	
	
	

	return fileName;
};

const disableBtn = (selectedFacility) => {
	return isEmptyVal(selectedFacility);
};

const getFacilityName = (currentFacility) => {
	if (isEmptyVal(currentFacility?.facilityID)) {
		return currentFacility?.communityName;
	} else {
		return "";
	}
};

const getFacilityOptions = (byCommunity) => {
	const keys = Object.keys(byCommunity);
	const sorted = sortByAlphaAsc(keys);
	return sorted;
};

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((entry) => {
		const first = entry?.FirstName;
		const last = entry?.LastName;
		const facilityName = entry?.CommunityName;
		const email = entry?.LoginNameByEmail ?? entry?.LoginName;

		if (
			first?.toLowerCase().includes(val) ||
			first?.toLowerCase().startsWith(val) ||
			last?.toLowerCase().includes(val) ||
			last?.toLowerCase().startsWith(val) ||
			facilityName?.toLowerCase().includes(val) ||
			facilityName?.toLowerCase().startsWith(val) ||
			email?.toLowerCase().includes(val) ||
			email?.toLowerCase().startsWith(val)
		) {
			return entry;
		} else {
			return null;
		}
	});
};

const advancedCopyAll = (searchVal, reportData, clonedReportData) => {
	if (!isEmptyVal(searchVal)) {
		// copy only search results
		const text = clonedReportData.reduce((data, item) => {
			const name = `${item?.LastName}, ${item?.FirstName}`;
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}`;
			const facility = `${item?.CommunityName}`;

			const userRow = `${name}: ${email} (${facility})\n\n`;
			data += `${userRow}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	} else {
		// copy all
		const text = reportData.reduce((data, item) => {
			const name = `${item?.LastName}, ${item?.FirstName}`;
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}`;
			const facility = `${item?.CommunityName}`;

			const userRow = `${name}: ${email} (${facility})\n\n`;
			data += `${userRow}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	}
};
const advancedCopyEmailsOnly = (searchVal, reportData, clonedReportData) => {
	if (!isEmptyVal(searchVal)) {
		// copy only search results
		const text = clonedReportData.reduce((data, item) => {
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}\n\n`;
			data += `${email}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	} else {
		// copy all
		const text = reportData.reduce((data, item) => {
			const email = `${item?.LoginNameByEmail ?? item?.LoginName}\n\n`;
			data += `${email}`;

			return data;
		}, "");

		

		return navigator.clipboard.writeText(text);
	}
};

const extractDataIntoLimitedArray = (data = []) => {
	const newList = data.map((entry) => {
		const newEntry = {
			CommunityName: entry?.CommunityName,
			Email: entry?.LoginNameByEmail ?? entry?.LoginName,
			UserName: `${entry?.FirstName} ${entry?.LastName}`,
			LastLogin: entry?.LastLogin ?? `UNKNOWN INFORMATION`,
			ResidentCount: entry?.ResidentCount ?? `UNKNOWN INFORMATION`,
		};
		return newEntry;
	});

	return newList;
};

const getUserFacilityData = (userEntry, reportMetrics) => {
	const { residentCounts } = reportMetrics;
	const id = userEntry?.CommunityName;
	const facility = residentCounts?.[id][0];

	return facility;
};

const CommunityActiveUsersReport = ({
	globalState = {},
	currentFacility = {},
	currentUser = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const { parentsMap } = globalState;
	const { raw: facilities, ids, names } = parentsMap;
	// local state
	const [selectedFacility, setSelectedFacility] = useState(
		getFacilityName(currentFacility)
	);
	const [isLoading, setIsLoading] = useState(false);
	const [reportMetrics, setReportMetrics] = useState({
		byCommunity: {},
		residentCounts: {},
	});
	const [reportData, setReportData] = useState({
		byCommunity: {},
		usersList: [],
		communities: [],
		active: [],
	});
	const [clonedReportData, setClonedReportData] = useState({
		byCommunity: {},
		usersList: [],
		communities: [],
		active: [],
	});

	const [searchVal, setSearchVal] = useState("");

	const handleFacility = (name, val) => {
		setSelectedFacility(val);
	};

	const searchUsers = (e) => {
		const { value } = e.target;

		if (isEmptyVal(value)) {
			setSearchVal("");
			return setClonedReportData(reportData);
		} else {
			const targetUsers = reportData?.active;
			const results = advancedSearch(value, targetUsers);

			setSearchVal(value);
			return setClonedReportData({ ...clonedReportData, active: results });
		}
	};

	// formats list entries as follows: "<last/>, <first/>: <email/> (<community/>)"
	const copyAll = (e) => {
		const report = reportData?.active;
		const cloned = clonedReportData?.active;
		advancedCopyAll(searchVal, report, cloned);
	};
	const copyEmailsOnly = (e) => {
		// iterate thru each record, extract email address format w/ line-break & append to string
		const report = reportData?.active;
		const cloned = clonedReportData?.active;

		advancedCopyEmailsOnly(searchVal, report, cloned);
	};

	const createAndDownloadCSV = (e) => {
		const { active } = reportData;
		// extract limited user data into new array
		const newData = extractDataIntoLimitedArray(active);
		const csvData = convertArrayDataToCSV(newData);
		// filename details
		// const community = selectedFacility.replace(/\s/g, "_");
		// const now = format(new Date(), "MM_DD_YYYY");
		// const fileName = `${community}-Users-${now}.csv`;
		const fileName = getCommunityFileName(selectedFacility);

		
		
		
		

		// auto-download .csv file
		// saveDataToCSV(csvData, fileName);
	};

	const fetchUsersList = async () => {
		const { token } = currentUser;

		// const usersList = await getActiveUsersList(token);
		const [usersList, residentData] = await Promise.all([
			getActiveUsersList(token),
			getActiveCommunitiesList(token),
		]);
		
		
		
		

		if (!isEmptyArray(usersList)) {
			const byCommunity = groupBy([...usersList], (x) => x?.CommunityName);
			const facilityOptions = getFacilityOptions(byCommunity);
			setClonedReportData({
				byCommunity: byCommunity,
				usersList: usersList,
				active: [],
				communities: [...facilityOptions],
			});
			const residentCountByCommunity = groupBy(
				[...residentData],
				(x) => x?.CommunityName
			);
			setReportMetrics({
				byCommunity: byCommunity,
				residentCounts: residentCountByCommunity,
			});
			return setReportData({
				byCommunity: byCommunity,
				usersList: usersList,
				active: [],
				communities: [...facilityOptions],
			});
		} else {
			setReportMetrics({});
			setClonedReportData([]);
			return setReportData([]);
		}
	};

	const loadCommunityUsers = (e) => {
		setIsLoading(false);
		const users = reportData?.byCommunity?.[selectedFacility];

		setReportData({
			...reportData,
			active: users,
		});
		setClonedReportData({
			...clonedReportData,
			active: users,
		});
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		fetchUsersList();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.CommunityActiveUsersReport}>
			<div className={styles.CommunityActiveUsersReport_details}>
				<div className={styles.CommunityActiveUsersReport_details_desc}>
					Please select a community below and click "Run Report" to load that
					community's active users' list.
				</div>
			</div>
			<div className={styles.CommunityActiveUsersReport_actions}>
				<ButtonSM
					handleClick={loadCommunityUsers}
					customStyles={customCSS.btn}
					isDisabled={disableBtn(selectedFacility)}
				>
					{isLoading ? <SpinnerSM color="#ffffff" /> : "Run Report"}
				</ButtonSM>
			</div>

			<div className={styles.CommunityActiveUsersReport_report}>
				<div className={styles.CommunityActiveUsersReport_report_summary}>
					{/* TOTAL ACTIVE USERS COUNT */}
					{!isEmptyArray(reportData?.active) && (
						<div
							className={styles.CommunityActiveUsersReport_report_summary_count}
						>
							Total:{" "}
							<b>
								{reportData?.active?.length ?? 0} active users at this community
								<b style={customCSS.commName}> ({selectedFacility})</b>
							</b>
						</div>
					)}
					{/* SEARCH RESULTS COUNT */}
					{!isEmptyArray(reportData?.active) && !isEmptyVal(searchVal) && (
						<div
							className={styles.CommunityActiveUsersReport_report_summary_count}
						>
							Search Results:{" "}
							<b style={customCSS.searching}>
								{clonedReportData?.active?.length ?? 0} matching results
							</b>
						</div>
					)}
				</div>
				<div className={styles.CommunityActiveUsersReport_report_selector}>
					<CustomDropdown
						key={`COMM-SELECTOR-${reportData?.communities?.length}`}
						id="facilitySelector"
						name="facilitySelector"
						options={reportData?.communities ?? []}
						selection={selectedFacility}
						setSelection={handleFacility}
						placeholder="Choose a community..."
						customStyles={customCSS.selector}
					/>
				</div>

				<div className={styles.CommunityActiveUsersReport_report_search}>
					<input
						type="text"
						id="searchUsersReport"
						name="searchUsersReport"
						value={searchVal}
						onChange={searchUsers}
						className={styles.CommunityActiveUsersReport_report_search_input}
						placeholder="Search by first/last, community, email..."
					/>
				</div>

				<div className={styles.CommunityActiveUsersReport_report_main}>
					<div
						className={styles.CommunityActiveUsersReport_report_entries_actions}
					>
						<ButtonSM
							title="Create a .csv file from the data"
							handleClick={createAndDownloadCSV}
							customStyles={customCSS.csv}
						>
							Download as .CSV
						</ButtonSM>
						<ButtonSM
							title="Only copies email addresses"
							handleClick={copyEmailsOnly}
							customStyles={customCSS.copyEmail}
						>
							Copy Emails ONLY
						</ButtonSM>
						<ButtonSM
							title="Copies ALL displayed users & their data"
							handleClick={copyAll}
							customStyles={customCSS.copyAll}
						>
							Copy All
						</ButtonSM>
					</div>

					{/* HEADINGS */}
					{/* HEADINGS */}
					<div
						className={styles.CommunityActiveUsersReport_report_main_headings}
					>
						<div
							className={
								styles.CommunityActiveUsersReport_report_main_headings_name
							}
						>
							Name
						</div>
						<div
							className={
								styles.CommunityActiveUsersReport_report_main_headings_email
							}
						>
							Email
						</div>
						<div
							className={
								styles.CommunityActiveUsersReport_report_main_headings_community
							}
						>
							Community
						</div>
						<div
							className={
								styles.CommunityActiveUsersReport_report_main_headings_login
							}
						>
							Last Login
						</div>
						<div
							className={
								styles.CommunityActiveUsersReport_report_main_headings_count
							}
						>
							Resident Count
						</div>
					</div>
					{/* HEADINGS */}
					{/* HEADINGS */}

					{/* ENTRIES */}
					{!isEmptyVal(selectedFacility) && (
						<>
							<div className={styles.CommunityActiveUsersReport_report_entries}>
								<div
									className={
										styles.CommunityActiveUsersReport_report_entries_users
									}
								>
									{/* SEARCH RESULTS */}
									{!isEmptyVal(searchVal) &&
										!isEmptyArray(clonedReportData?.active) &&
										clonedReportData?.active.map((entry, idx) => (
											<UserReportEntry
												key={`FACILITY-REPORT-${idx}`}
												user={entry}
												facilityData={getUserFacilityData(entry, reportMetrics)}
											/>
										))}
									{/* RAW RESULTS */}
									{!isEmptyArray(reportData?.active) &&
										isEmptyVal(searchVal) &&
										reportData?.active.map((entry, idx) => (
											<UserReportEntry
												key={`FACILITY-REPORT-${idx}`}
												user={entry}
												currentUser={currentUser}
												facilityData={getUserFacilityData(entry, reportMetrics)}
											/>
										))}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommunityActiveUsersReport;

CommunityActiveUsersReport.defaultProps = {};

CommunityActiveUsersReport.propTypes = {};

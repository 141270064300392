import React, { useState, useEffect } from "react";
import styles from "../../css/reports/UserReportDetails.module.scss";
import { PropTypes } from "prop-types";
import {
	getUserProfile,
	processUserInfo,
	processUserProfile,
} from "../../helpers/utils_user";
import { format, isAfter } from "date-fns";
import { blueGrey } from "../../helpers/utils_styles";
import { getUserSecurityInfo } from "../../helpers/utils_security";
// components
import CopyText from "../forms/CopyText";
import Divider from "../forms/Divider";

const customCSS = {
	copy: {
		fontSize: "1.8rem",
		fontWeight: "800",
		color: blueGrey[800],
	},
};

const getLastLogin = (details = {}) => {
	const dateLogin = details?.UserLogin?.ModifiedDate;
	const dateAdv = details?.AdvUser?.LastLoginDate;

	const last = isAfter(dateLogin, dateAdv) ? dateLogin : dateAdv;
	const date = format(last, "MM/DD/YYYY");

	return date;
};

const UserReportDetails = ({
	currentUser = {},
	user = {},
	facilityData = {},
}) => {
	const [userDetails, setUserDetails] = useState({
		AdvUser: {},
		AdvUserCommunities: [],
		UserLogin: {},
		UserLoginFacilities: [],
	});

	const getDetails = async (e) => {
		const { token } = currentUser;
		const id = user?.UserLoginID;
		// const raw = await getUserProfile(token, id);

		const [rawProfile, info] = await Promise.all([
			getUserProfile(token, id),
			getUserSecurityInfo(token, id),
		]);

		const profile = processUserProfile(rawProfile);
		const newInfo = processUserInfo(info);

		
		
		
		
		
		

		// if not empty object
		if (profile) {
			const mergedDetails = {
				...newInfo,
				...profile,
			};
			return setUserDetails(mergedDetails);
		} else {
			return setUserDetails({
				AdvUser: {},
				AdvUserCommunities: [],
				UserLogin: {},
				UserLoginFacilities: [],
			});
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		getDetails();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.UserReportDetails}>
			<div className={styles.UserReportDetails_about}>
				{/* USER DETAILS */}
				<div className={styles.UserReportDetails_about_item}>
					Name:{" "}
					<b>
						<CopyText
							text={`${user?.FirstName} ${user?.LastName}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Email:{" "}
					<b>
						<CopyText
							text={`${user?.LoginNameByEmail ?? user?.LoginName}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Title:{" "}
					<b>
						<CopyText
							text={`${userDetails?.AdvUser?.strTitle ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					User ID:{" "}
					<b>
						<CopyText
							text={`${user?.UserLoginID?.toUpperCase()}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Last Login: <b>{getLastLogin(userDetails)}</b>
				</div>

				<Divider />

				{/* COMMUNITY DETAILS */}
				<div className={styles.UserReportDetails_about_item}>
					Community:{" "}
					<b>
						<CopyText
							text={`${user?.CommunityName}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Resident Count:{" "}
					<b style={{ marginLeft: "1rem" }}>{facilityData?.ResidentCount}</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Address:{" "}
					<b>
						<CopyText
							text={`${facilityData?.Address1 ?? ""}`}
							customStyles={customCSS.copy}
						/>
					</b>
					<b>
						<CopyText
							text={`${facilityData?.City ?? ""}, ${
								facilityData?.State ?? ""
							} ${facilityData?.Zip ?? ""}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Email:{" "}
					<b>
						<CopyText
							text={`${facilityData?.Email ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Phone:{" "}
					<b>
						<CopyText
							text={`${facilityData?.Phone ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Fax:{" "}
					<b>
						<CopyText
							text={`${facilityData?.Fax ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Time Zone:{" "}
					<b>
						<CopyText
							text={`${facilityData?.LocalTimeZoneID ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Executive Director:{" "}
					<b>
						<CopyText
							text={`${facilityData?.ExecDirector ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Assisted Living Director:{" "}
					<b>
						<CopyText
							text={`${facilityData?.ALDirector ?? "N/A"}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
				<div className={styles.UserReportDetails_about_item}>
					Facility ID:{" "}
					<b>
						<CopyText
							text={`${facilityData?.guidFacility?.toUpperCase()}`}
							customStyles={customCSS.copy}
						/>
					</b>
				</div>
			</div>
		</div>
	);
};

export default UserReportDetails;

UserReportDetails.defaultProps = {};

UserReportDetails.propTypes = {};

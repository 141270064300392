import React, { useState, useRef } from "react";
import styles from "../../css/facility/EmarAccessController.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { SERVICES } from "../../helpers/utils_apps";
import { setFacilityAppAccess } from "../../helpers/utils_facility";
import { red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	addFacilityToEmar,
	removeFacilityToEmar,
} from "../../helpers/utils_emar";
// components
import ButtonSM from "../shared/ButtonSM";
import FacilityAccessSwitch from "./FacilityAccessSwitch";

const customCSS = {
	save: {
		padding: ".5rem 1rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
	},
	cancel: {
		padding: ".5rem 1rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.6rem",
		marginRight: "1rem",
	},
};

const wasTouched = (keyName, touched) => {
	const wasTouched = touched[keyName] ?? false;
	return wasTouched;
};

const enableBtn = (formState, currentFacility) => {
	if (isEmptyVal(currentFacility?.facilityID)) return false;

	const { touched } = formState;
	const portalTouched = wasTouched("AdminPortal", touched);
	const trackerTouched = wasTouched("AdvantageTracker", touched);
	const legacyTouched = wasTouched("SeniorCareVB", touched);
	const emarTouched = wasTouched("Chart Meds", touched);

	const hasChanges =
		trackerTouched || legacyTouched || portalTouched || emarTouched;

	return hasChanges;
};

const EmarAccessController = ({
	app = {},
	initialVal,
	currentUser,
	currentFacility,
	handleAlert,
}) => {
	const initialState = useRef(initialVal);
	const { formState, setFormState, handleReset } = useForm({
		// 'Chart Meds': true|false
		[app.appName]: initialVal,
	});
	const { values, touched } = formState;

	const handleEnableAccess = (e) => {
		const { name, checked } = e.target;
		setFormState({
			...formState,
			values: {
				...values,
				[name]: checked,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	// fires off single app access update
	const saveAccessChanges = async (appName) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		// ChartMeds Interface application ID
		const appID = SERVICES[appName].appID ?? 19;
		const isAccessible = values["Chart Meds"];

		
		
		
		
		

		// fire off request
		// const wasUpdated = true;
		if (isAccessible) {
			const wasUpdated = await addFacilityToEmar(token, facilityID, appID);

			if (wasUpdated) {
				return handleAlert(appName, wasUpdated);
			} else {
				return handleAlert(appName, wasUpdated);
			}
		} else {
			const wasUpdated = await removeFacilityToEmar(token, facilityID, appID);

			if (wasUpdated) {
				return handleAlert(appName, wasUpdated);
			} else {
				return handleAlert(appName, wasUpdated);
			}
		}

		// const wasUpdated = await addFacilityToEmar(token, facilityID, appID);

		// const wasUpdated = await setFacilityAppAccess(token, {
		// 	appID,
		// 	facilityID,
		// 	isAccessible,
		// });
		// if (wasUpdated) {
		// 	return handleAlert(appName, wasUpdated);
		// } else {
		// 	return handleAlert(appName, wasUpdated);
		// }
	};

	const cancelChanges = (e) => {
		handleReset(e);
	};

	

	return (
		<div className={styles.EmarAccessController}>
			<div className={styles.EmarAccessController_switch}>
				<FacilityAccessSwitch
					key={`ACCESS--${app.appName}`}
					appName={app.appName}
					label={app.label}
					isLocked={false}
					isEnabled={values?.[app.appName]}
					handleAccess={handleEnableAccess}
				/>
			</div>
			<div className={styles.EmarAccessController_actions}>
				<ButtonSM handleClick={cancelChanges} customStyles={customCSS.cancel}>
					Cancel
				</ButtonSM>
				<ButtonSM
					isDisabled={!enableBtn(formState, currentFacility)}
					handleClick={() => saveAccessChanges(app.appName)}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default EmarAccessController;

EmarAccessController.defaultProps = {};

EmarAccessController.propTypes = {};
